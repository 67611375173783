const colors = {
  lightGray: "#ECECEC",
  black: "#3F3F3F",
  lightBlack: "#545454",
  yellow: "#FFCB05",
  lightYellow: "#FFDB50",
  white: "#FFFFFF",
};

export default colors;
